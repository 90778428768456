import { React } from 'react';
import { Tailwind, Head, Body, Container, Heading, Text, Img, Section, Font } from '@react-email/components';

import { tailwindConfig } from './tailwindconfig';

const CodeEmail = ({ code }) => (
  <Tailwind config={tailwindConfig}>
    <Head />
    <Font
      fontFamily='Outfit'
      fallbackFontFamily={['-apple-system', 'BlinkMacSystemFont']}
    />
    <Body style={{
      backgroundImage: `url(https://urolab.s3.eu-north-1.amazonaws.com/email.png)`,
      backgroundPosition: '200px center, bottom left',
      backgroundRepeat: 'no-repeat, no-repeat',
      height: '100vh',
      width: '100%',
      display: 'flex',
    }}>
      {/* Desktop */}
      <Container className='hidden md:block w-full h-full ml-28 mt-48'>
        <Section>
          <div className='text-center'>
            <Img src="https://urolab.s3.eu-north-1.amazonaws.com/urolab-logo.png" alt="Urolab Logo" className='w-64 mx-auto' />
            <Heading className='text-5xl font-medium mt-5 whitespace-nowrap'>Código de Verificação</Heading>
            <Text className='text-base mt-5 mb-18 font-normal' style={{ color:'#424242' }}>
                Confirme a sua entidade inserindo o código de uso único a seguir.
            </Text>
            <Text className='w-full h-24 text-5xl text-center rounded-3xl text-white outline-none'
              style={{ backgroundColor: 'rgba(8, 7, 8, 0.15)',
                letterSpacing: '40px',
                fontFamily:'Outfit, -apple-system, BlinkMacSystemFont',
                lineHeight: '100px',
                textIndent: '30px'
              }}
            >{code}</Text>
          </div>
        </Section>
      </Container>
      {/* Mobile */}
      <Container className='md:hidden w-auto h-full'>
        <Section>
          <div className='text-center'>
            <Img src="https://urolab.s3.eu-north-1.amazonaws.com/urolab-logo.png" alt="Urolab Logo" className='w-64 ml-auto mr-auto' />
            <Heading className='text-5xl font-medium mt-5 mr-4'>Código de Verificação</Heading>
            <Text className='w-80 text-base mt-5 mb-18  ml-auto mr-auto font-normal' style={{ color:'#424242' }}>
                Confirme a sua entidade inserindo o código de uso único a seguir.
            </Text>
            <Text className='w-80 h-18 mr-auto ml-auto text-5xl text-center rounded-full text-white outline-none'
              style={{ backgroundColor: 'rgba(8, 7, 8, 0.15)',
                letterSpacing: '40px',
                fontFamily:'Outfit, -apple-system, BlinkMacSystemFont',
                lineHeight: '100px',
                textIndent: '30px'
              }}
            >{code}</Text>
          </div>
        </Section>
      </Container>
    </Body>
  </Tailwind>
);

export default CodeEmail;
